export default {
  profile: {
    en: "Profile",
    ja: "プロフィール"
  },
  connectWallet: {
    en: 'Connect Wallet',
    ja: 'コネクトウォレット'
  },
  verifyPersonalData: {
    en: 'KYC Verification coming soon...',
    ja: 'KYC検証は近日公開予定...'
  },
  copied:{
    en: 'Copied',
    ja: 'コピー機'
  },
  disconnectWallet:{
    en: 'Disconnect wallet',
    ja: 'ウォレットの接続を解除する'
  },
  metamaskWalletDisconnectNotificationTitle:{
    en: 'Wallet not disconnected',
    ja: 'ウォレットが切断されていない'
  },
  metamaskWalletDisconnectNotificationSubtitle:{
    en: 'Please use Metamask to disconnect',
    ja: 'メタマスクで接続を解除してください'
  }
}